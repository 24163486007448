import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  StarOutlinedIcon,
  StarFilledIcon,
  HouseOutlinedIcon,
  HouseFilledIcon,
  ChartOutlinedIcon,
  ChartFilledIcon,
  QuestionOutlinedIcon,
  QuestionFilledIcon,
  DocumentsIcon,
  DocumentsFilledIcon,
  MapIcon,
  MapFilledIcon,
  CheckIcon,
  CheckFilledIcon,
} from '@flock/shared-ui'
import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import { getHoldingPeriod } from '@flock/utils/src/money/flexibleCashFlowCalculator'
import OfferDetails from './OfferDetails'
import OfferDocuments from './OfferDocuments/OfferDocuments'
import AppreciationPotential from './AppreciationPotential/AppreciationPotential'
import OfferPagePaper from './OfferPagePaper'
import OfferPageSection from './OfferPageSection'

import { OfferPageData } from './offerPageTypes'
import OfferTasks from './OfferTasks/OfferTasks'

import OfferPortfolio from './OfferPortfolio/OfferPortfolio'

import { scrollIntoView, useInView } from '../utils'

import OfferPageSidebar from './OfferPageSidebar/OfferPageSidebar'
import OfferExchange from './OfferExchange/OfferExchange'
import FloatingMessage from './FloatingMessage'
import OfferQuestions from './OfferQuestions/OfferQuestions'
import FlexibleCashflowCalculator from './FlexibleCashflowCalculator/FlexibleCashflowCalculator'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type OfferPageProps = {
  pageData: OfferPageData
  leadUuid: string
}

const OfferPage = (props: OfferPageProps) => {
  const { pageData, leadUuid } = props

  const {
    zipcode,
    cityStateZipcode,
    hasCityStateZipcodeOverride,
    latitude,
    longitude,
    propertyCondition,
    scheduleInspectionRequested,
    contributionAgreementRequested,
    mortgage,
    hideValuation,
    finalOffer,
    uwCashOnCashYield,
    hideNetYield,
    prelimOffer,
    showRangedValuation,
    offerLow,
    offerHigh,
    expiryDate,
    updateScheduleInspectionRequested,
    updateContributionAgreementRequested,
    updateRefreshOfferRequested,
    refreshOfferRequested,
    saamValuationType,
    leadStage,
    street,
    slackThreadUrl,
    leadStatus,
    inspectionDate,
    leadDocuments,
    staticDocumentOverrides,
    bedrooms,
    bathrooms,
    halfBaths,
    propertyTypeDisplayString,
    transactionType,
    isOverriddenAddress,
    fullName,
    customerUuid,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    portfolioDrawerContent,
    equityOverride,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    cashTakeout,
    capexCosts,
    isPreliminary,
    shareCount,
    equityAmount,
    netYieldOverride,
    holdingPeriodOverride,
    showCashFlowDeductions,
  } = pageData

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const offerPortfolioText = isMobile
    ? 'Offers are based on sale comps, condition, and market data.'
    : 'Flock offers are based on sale comps, property condition, and market rent data.'

  // SELECT * FROM
  // customers
  // WHERE
  // full_name in
  //   ('Daniel Bruns',
  //   'Geralyn Lindow',
  //   'Mitch Gale',
  //   'Suzanne Ishak',
  //   'Shawn Miller',
  //   'Josh Driscoll',
  //   'John Graves',
  //   'Grant Pape',
  //   'Rick Ferguson')

  const LEGACY_CALCULATOR_CUSTOMERS: string | string[] = [
    '84e954f6-180c-4d6d-9fa3-bbb47c546aa1',
    'dbcfd2f7-3cc3-451f-9629-0112988c54a2',
    '37718468-82c5-4cf3-b96b-c4235e8ae2c8',
    '42c5418a-4c76-4ea5-b60f-e064de95b86e',
    'df4b2dfa-5ef6-479f-9f3f-c6ccaeb1c9f3',
    '8216a85a-4f99-4769-9b77-08421b3b67d7',
    '12a1816f-4e10-46a7-a012-1ed557da193a',
    '99315c59-cef7-4951-b9c2-53bd5d2465d7',
    'e9410764-74f8-4dd3-bf44-660a4bfb58ee',
  ]
  const [selectedSection, setSelectedSection] = useState(0)
  const [sidebarHeight, setSidebarHeight] = useState(0)
  const sidebarRef = useCallback((domElement: HTMLDivElement) => {
    if (domElement !== null) {
      setSidebarHeight(domElement.clientHeight)
    }
  }, [])

  const sectionIds = useMemo(
    () =>
      shareCount && shareCount > 0
        ? [
            'offer',
            'details',
            'exchange',
            'earnings',
            'questions',
            'next-steps',
            'documents',
          ]
        : [
            'offer',
            'details',
            'earnings',
            'questions',
            'next-steps',
            'documents',
          ],
    [shareCount]
  )

  const currentSection = useInView(sectionIds)

  const sidebarItems = [
    {
      title: 'Offer',
      unselectedIcon: StarOutlinedIcon,
      selectedIcon: StarFilledIcon,
      id: 'offer',
    },
    {
      title: 'Details',
      unselectedIcon: HouseOutlinedIcon,
      selectedIcon: HouseFilledIcon,
      id: 'details',
    },
    {
      title: 'Exchange',
      unselectedIcon: MapIcon,
      selectedIcon: MapFilledIcon,
      id: 'exchange',
      hide: !shareCount || shareCount < 0,
    },
    {
      title: 'Investment',
      unselectedIcon: ChartOutlinedIcon,
      selectedIcon: ChartFilledIcon,
      id: 'earnings',
    },
    {
      title: 'FAQs',
      unselectedIcon: QuestionOutlinedIcon,
      selectedIcon: QuestionFilledIcon,
      id: 'questions',
    },
    {
      title: 'Next steps',
      unselectedIcon: CheckIcon,
      selectedIcon: CheckFilledIcon,
      id: 'next-steps',
    },
    {
      title: 'Documents',
      unselectedIcon: DocumentsIcon,
      selectedIcon: DocumentsFilledIcon,
      id: 'documents',
    },
  ]

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setSelectedSection(sectionIds.indexOf(currentSection))
  }, [currentSection, sectionIds])

  // if the lead only provided a full name, remove the "Unknown" part
  const displayFullName = fullName?.replace(' Unknown', '')

  return (
    <>
      <OfferPageSidebar
        sidebarRef={sidebarRef}
        sidebarHeight={sidebarHeight}
        leadStatus={leadStatus}
        sidebarItems={sidebarItems}
        onChangeSidebar={onChangeSidebar}
        selectedSection={selectedSection}
      />
      <OfferPageSection
        name="offer-details"
        backgroundColor="trustBlue.main"
        id="offer"
      >
        <OfferDetails
          name={displayFullName}
          uwCashOnCashYield={uwCashOnCashYield}
          street={street}
          equityAmount={equityAmount}
          zipcode={zipcode}
          cityStateZipcode={cityStateZipcode}
          expiryDate={expiryDate}
          latitude={latitude}
          longitude={longitude}
          hideValuation={hideValuation}
          finalOffer={finalOffer}
          prelimOffer={prelimOffer}
          showRangedValuation={showRangedValuation}
          offerLow={offerLow}
          offerHigh={offerHigh}
          propertyCondition={propertyCondition}
          hasCityStateZipcodeOverride={hasCityStateZipcodeOverride}
          leadStage={leadStage}
          isOverriddenAddress={isOverriddenAddress}
          saamValuationType={saamValuationType}
          leadStatus={leadStatus}
          inspectionDate={inspectionDate}
          leadUuid={leadUuid}
          transactionType={transactionType}
          scheduleInspectionRequested={scheduleInspectionRequested}
          updateScheduleInspectionRequested={updateScheduleInspectionRequested}
          contributionAgreementRequested={contributionAgreementRequested}
          updateContributionAgreementRequested={
            updateContributionAgreementRequested
          }
          updateRefreshOfferRequested={updateRefreshOfferRequested}
          refreshOfferRequested={refreshOfferRequested}
        />
      </OfferPageSection>
      <OfferPageSection
        name="home-details"
        backgroundColor="trustBlue.main"
        id="details"
        shouldHide={
          isOverriddenAddress &&
          transactionType !== Core_TransactionType.TransactionTypePortfolio_721
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={isMobile ? '16px' : '24px'}
        >
          <Typography color="moneyGreen.main" variant="cta">
            {offerPortfolioText}
          </Typography>
          <OfferPagePaper>
            <OfferPortfolio
              street={street}
              cityStateZip={cityStateZipcode}
              bedrooms={bedrooms}
              bathrooms={(bathrooms || 0) + (halfBaths || 0) / 2}
              homeType={propertyTypeDisplayString}
              condition={propertyCondition}
              transactionType={transactionType}
              leadName={displayFullName}
              portfolioName={portfolioName}
              portfolioDoorCount={portfolioDoorCount}
              portfolioAssetCount={portfolioAssetCount}
              portfolioCityState={cityStateZipcode || portfolioCityState}
              portfolioSFRDoors={portfolioSFRDoors}
              portfolioMultifamilyDoors={portfolioMultifamilyDoors}
              capex={capexCosts}
              offerPrice={finalOffer || prelimOffer}
            />
          </OfferPagePaper>
        </Box>
      </OfferPageSection>
      {shareCount && shareCount > 0 && (
        <OfferPageSection
          name="appreciation-potential"
          backgroundColor="trustBlue.main"
          id="exchange"
        >
          <Box
            pb={isMobile ? '16px' : '24px'}
            display="flex"
            gap="32px"
            flexDirection="column"
          >
            <Typography variant="cta" color="moneyGreen.main">
              You receive ownership in Flock&apos;s Fund based on the value of
              your
              {transactionType ===
              Core_TransactionType.TransactionTypePortfolio_721
                ? ' homes.'
                : ' house.'}
            </Typography>
          </Box>
          <OfferPagePaper>
            <OfferExchange
              finalOffer={finalOffer || prelimOffer}
              street={street}
              showRangedValuation={showRangedValuation}
              onboardingFee={portfolioDrawerContent.onboardingFee}
              closingCostsOverride={closingCostsOverride}
              shouldOverrideClosingCosts={shouldOverrideClosingCosts}
              mortgage={mortgage || 0}
              transactionType={transactionType}
              rentReduction={portfolioDrawerContent.rentReduction || 0}
              cashTakeout={cashTakeout || 0}
              daysInRemodelDeduction={
                portfolioDrawerContent?.daysInRemodelDeduction || 0
              }
              brokerCommission={portfolioDrawerContent?.brokerCommission || 0}
              equityOverride={equityOverride || 0}
            />
          </OfferPagePaper>
        </OfferPageSection>
      )}
      {equityOverride
        ? equityOverride > 0
        : equityAmount > 0 && (
            <OfferPageSection
              name="appreciation-potential"
              backgroundColor="trustBlue.main"
              id="earnings"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={isMobile ? '16px' : '24px'}
              >
                <Typography color="moneyGreen.main" variant="cta">
                  As a Flock Client, you&apos;ll earn income and appreciation
                  from the entire portfolio of homes.
                </Typography>
                <OfferPagePaper>
                  {/* TODO: Change this conditional */}
                  {!LEGACY_CALCULATOR_CUSTOMERS.includes(customerUuid) ? (
                    <FlexibleCashflowCalculator
                      initialValuation={finalOffer || prelimOffer}
                      uwCashOnCashYield={uwCashOnCashYield}
                      hideNetYield={hideNetYield}
                      onboardingFee={portfolioDrawerContent.onboardingFee}
                      rentReduction={
                        pageData.portfolioDrawerContent.rentReduction || 0
                      }
                      daysInRemodelDeduction={
                        pageData.portfolioDrawerContent
                          .daysInRemodelDeduction || 0
                      }
                      mortgageAmount={mortgage || 0}
                      equityOverride={equityOverride || 0}
                      shouldOverrideClosingCosts={
                        shouldOverrideClosingCosts || false
                      }
                      closingCostsOverride={closingCostsOverride || 0}
                      cashTakeout={cashTakeout || 0}
                      netYieldOverride={netYieldOverride}
                      showCashFlowDeductions={showCashFlowDeductions}
                      holdingPeriodOverride={holdingPeriodOverride}
                    />
                  ) : (
                    <AppreciationPotential
                      leadUuid={leadUuid}
                      initialValuation={finalOffer || prelimOffer}
                      onboardingFee={portfolioDrawerContent.onboardingFee}
                      rentReduction={
                        pageData.portfolioDrawerContent.rentReduction || 0
                      }
                      daysInRemodelDeduction={
                        pageData.portfolioDrawerContent
                          .daysInRemodelDeduction || 0
                      }
                      isPreliminary={isPreliminary}
                      lowerValuationNumber={offerLow}
                      upperValuationNumber={offerHigh}
                      mortgageAmount={mortgage || 0}
                      equityOverride={equityOverride || 0}
                      shouldOverrideClosingCosts={
                        shouldOverrideClosingCosts || false
                      }
                      closingCostsOverride={closingCostsOverride || 0}
                      cashTakeout={cashTakeout || 0}
                      capexCosts={capexCosts || 0}
                      hideValuation={hideValuation}
                      brokerCommission={
                        portfolioDrawerContent?.brokerCommission || 0
                      }
                    />
                  )}
                </OfferPagePaper>
              </Box>
            </OfferPageSection>
          )}
      <OfferPageSection
        name="offer-questions"
        backgroundColor="trustBlue.main"
        id="questions"
      >
        <OfferQuestions />
      </OfferPageSection>
      <OfferPageSection
        name="offer-tasks"
        backgroundColor="trustBlue.main"
        id="next-steps"
      >
        <OfferTasks
          leadStatus={leadStatus}
          inspectionDate={inspectionDate}
          zipcode={zipcode}
          expiryDate={expiryDate}
          leadStage={leadStage}
          leadUuid={leadUuid}
          transactionType={transactionType}
        />
      </OfferPageSection>
      <OfferPageSection
        name="offer-documents"
        backgroundColor="trustBlue.main"
        id="documents"
      >
        <OfferDocuments
          leadDocuments={leadDocuments || []}
          staticDocumentOverrides={staticDocumentOverrides || {}}
          slackThreadUrl={slackThreadUrl}
        />
      </OfferPageSection>
      {isMobile && (
        <OfferPageSection
          name="floating-cta-mobile"
          backgroundColor="trustBlue.main"
          id="floating-cta-mobile"
        >
          <FloatingMessage leadStatus={leadStatus} />
        </OfferPageSection>
      )}
      <OfferPageSection
        name="offer-disclaimers"
        backgroundColor="trustBlue.main"
        id="disclaimers"
      >
        {!hideNetYield && (netYieldOverride || uwCashOnCashYield) ? (
          <Typography variant="p4" color="gray5.main">
            To benefit from the terms outlined in this offer, it is a
            requirement to maintain your investment in the Fund for at least{' '}
            {holdingPeriodOverride ||
              getHoldingPeriod(netYieldOverride || uwCashOnCashYield)}{' '}
            years.
            <br />
            <sup>1</sup> This model projects meeting a target annual return of
            10% with annual cash flow. Cash flow includes your{' '}
            {Math.min(
              Math.round(
                (netYieldOverride || uwCashOnCashYield) * 10000 - 200
              ) / 100,
              4
            )}
            % Annual Cash Flow Allotment + 2% Top Up Allowance. Your capital
            account will be debited 0.10% for every 1% in cash flow you receive
            above your annual allotment. This deduction compensates all other
            clients in the Fund. Taking cash beyond 2-3% annually in the first
            2-3 years of owning Flock may have tax impacts. Actual impacts are
            expected to be limited and generally in the form of capital gains
            tax on the amount of cash exceeding this threshold.
            <br />
          </Typography>
        ) : null}
        <Typography variant="p4" color="gray5.main">
          Performance Disclaimer: In considering the target performance
          information contained herein, prospective investors should bear in
          mind that past or targeted performance is not a guarantee, projection
          or prediction and is not necessarily indicative of future results.
          There can be no assurance that the Fund will achieve comparable
          results, that targeted returns will be met or that the Fund will be
          able to implement its investment strategy and investment approach or
          achieve its investment objectives. Actual gross and net returns for
          the Fund may vary significantly from the targeted returns set forth
          herein.
        </Typography>
      </OfferPageSection>
    </>
  )
}

export default OfferPage
